.react-bs-table .react-bs-container-header .sort-column,
td.react-bs-table-expand-cell,
th.react-bs-table-expand-cell > div {
  cursor: pointer;
}
.react-bs-table-container .react-bs-table-search-form {
  margin-bottom: 0;
}
.react-bs-table-bordered {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.react-bs-table table {
  margin-bottom: 0;
  table-layout: fixed; // auto; june16 - need to replace 'fixed' to 'auto' when fixing
}
.react-bs-table table td,
.react-bs-table table th {
  overflow: hidden;
  // white-space: nowrap;
  text-overflow: ellipsis;
  // padding: 12px; june16 - need to uncomment to see when fixing
  // border: 0.5px solid #9aaee7; june16 - need to uncomment to see when fixing

  
}
.react-bs-table-pagination {
  margin-top: 10px;
}
.react-bs-table-tool-bar {
  margin-bottom: 5px;
}
.react-bs-container-footer,
.react-bs-container-header {
  overflow: hidden;
  width: 100%;
}
.react-bs-container-body {
  overflow: auto;
  width: 100%;
}
.react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0;
}
.react-bs-table .table-bordered {
  border: 0;
  outline: 0 !important;
}

/* Custom CSS file for striped table */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.react-bs-table .table-bordered > thead > tr > td,
.react-bs-table .table-bordered > thead > tr > th {
  border-bottom-width: 2px;
}
.react-bs-table .table-bordered > tbody > tr > td {
  outline: 0 !important;
}
.react-bs-table .table-bordered > tbody > tr > td.default-focus-cell {
  outline: #6495ed solid 3px !important;
  outline-offset: -1px;
}
.react-bs-table .table-bordered > tfoot > tr > td,
.react-bs-table .table-bordered > tfoot > tr > th {
  border-top-width: 2px;
  border-bottom-width: 0;
}
.react-bs-table .table-bordered > tbody > tr > td:first-child,
.react-bs-table .table-bordered > tbody > tr > th:first-child,
.react-bs-table .table-bordered > tfoot > tr > td:first-child,
.react-bs-table .table-bordered > tfoot > tr > th:first-child,
.react-bs-table .table-bordered > thead > tr > td:first-child,
.react-bs-table .table-bordered > thead > tr > th:first-child {
  border-left-width: 0;
}
.react-bs-table .table-bordered > tbody > tr > td:last-child,
.react-bs-table .table-bordered > tbody > tr > th:last-child,
.react-bs-table .table-bordered > tfoot > tr > td:last-child,
.react-bs-table .table-bordered > tfoot > tr > th:last-child,
.react-bs-table .table-bordered > thead > tr > td:last-child,
.react-bs-table .table-bordered > thead > tr > th:last-child {
  border-right-width: 0;
}
.react-bs-table .table-bordered > thead > tr:first-child > td,
.react-bs-table .table-bordered > thead > tr:first-child > th {
  border-top-width: 0;
}
.react-bs-table .table-bordered > tfoot > tr:last-child > td,
.react-bs-table .table-bordered > tfoot > tr:last-child > th {
  border-bottom-width: 0;
}
.react-bs-table .react-bs-container-footer > table > thead > tr > th,
.react-bs-table .react-bs-container-header > table > thead > tr > th {
  vertical-align: top;
  font-weight: 400;
  font-size: 0.88rem;
}
.react-bs-table .react-bs-container-footer > table > thead > tr > th .filter,
.react-bs-table .react-bs-container-header > table > thead > tr > th .filter {
  font-weight: 400;
}
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .filter::-webkit-input-placeholder,
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .number-filter-input::-webkit-input-placeholder,
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .select-filter
  option[value=""],
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .select-filter.placeholder-selected {
  color: #d3d3d3;
  font-style: italic;
}
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .filter::-webkit-input-placeholder,
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .number-filter-input::-webkit-input-placeholder,
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .select-filter
  option[value=""],
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .select-filter.placeholder-selected {
  color: #d3d3d3;
  font-style: italic;
}
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .select-filter.placeholder-selected
  option:not([value=""]),
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .select-filter.placeholder-selected
  option:not([value=""]) {
  color: initial;
  font-style: initial;
}
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .date-filter,
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .number-filter,
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .date-filter,
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .number-filter {
  display: flex;
}
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .date-filter-input,
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .number-filter-input,
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .date-filter-input,
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .number-filter-input {
  margin-left: 5px;
  float: left;
  width: calc(100% - 67px - 5px);
}
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .date-filter-comparator,
.react-bs-table
  .react-bs-container-footer
  > table
  > thead
  > tr
  > th
  .number-filter-comparator,
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .date-filter-comparator,
.react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .number-filter-comparator {
  width: 67px;
  float: left;
}
.react-bs-container .textarea-save-btn {
  position: absolute;
  z-index: 100;
  right: 0;
  top: -21px;
}
.react-bs-table-no-data {
  text-align: center;
}
.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}
.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}
.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0);
  transform: scale(1) rotateX(0);
  transition: all 150ms ease-in;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}
.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent;
}
.animated {
  animation-fill-mode: both;
}
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: 0.75s;
}
.animated.shake {
  animation-duration: 0.3s;
}
@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  50%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  30%,
  70% {
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  animation-name: shake;
}
@keyframes bounceIn {
  20%,
  40%,
  60%,
  80%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  animation-name: bounceIn;
}
@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  animation-name: bounceOut;
}
