@primary-color: var(--appButtonColor); //#1890ff;
@border-radius-base: 0.25rem;
.container {
    :global {
        --antd-wave-shadow-color: transparent;

        .ant-input, .ant-picker, .ant-select, .ant-select-multiple .ant-select-selector, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            height: 35px;
            border-radius: 4px;
        }

        .ant-input:hover, .ant-input:focus, 
        .ant-picker-input > input:hover, .ant-picker-input > input:focus, 
        .ant-picker:hover, .ant-picker-focused,
        .ant-select:hover, .ant-select-focused,
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-color: var(--appButtonColor);
            box-shadow: none;
        }

        .ant-picker-range .ant-picker-active-bar {
            background: var(--appButtonColor);
        }

        .ant-btn {
            background: var(--appButtonColor);
            border-color: var(--appButtonColor);
            border-radius: 0.25rem;
            padding: 10px 25px;
        }

        .ant-btn:hover, .ant-btn:focus {
            background: var(--appButtonColor);
            border-color: var(--appButtonColor);
            border-radius: 0.25rem;
        }

        .ant-progress-success-bg, .ant-progress-bg {
            background-color: var(--appButtonColor);
        }
    } 
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--appButtonColor) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--appButtonColor) !important;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: var(--appButtonColor) !important;
    filter: brightness(150%);
}

// .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
//     background: var(--appButtonColor) !important;
//     filter: brightness(180%);
// }

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: var(--appButtonColor) !important;
    filter: brightness(120%);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: var(--appButtonColor) !important;
    filter: brightness(150%);
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: var(--appButtonColor) !important;
    filter: brightness(150%);
}

.ant-btn-primary, .ant-btn-primary:focus {
    background: var(--appButtonColor) !important;
    border-color: var(--appButtonColor) !important;
    color: #2b2b2b !important;
}

.ant-btn-primary:hover {
    background: var(--appButtonColor) !important;
    border-color: var(--appButtonColor) !important;
    filter: brightness(120%);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--appButtonColor) !important;
    opacity: 0.5;
    // filter: brightness(120%);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: var(--appButtonColor) !important;
}