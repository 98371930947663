.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.input-group-text {
  font-size: 14px;
  border-radius: 0;
}

.container__wrap {
  padding-left: 0;
  padding-top: 70px; //90px;
  min-height: 100vh;
  width: 100%;
  transition: padding-left 0.3s;
  flex: auto; //added
  flex-wrap: wrap; //added

  @include themify($themes) {
    background: themed('colorBackgroundBody'); //colorBackground
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;
  }
}

.layout {

  &.layout--collapse {

    &+.container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation {

    &+.container__wrap {

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.Toastify__toast-body {
  white-space: pre-line;
}