@import "../settings/variable.scss";
@import "../settings/login.scss";

main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.6;

  @include themify($themes) {
    color: themed("colorText");
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-link;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-link-hover;
  }
}

img {
  width: 100%;
}

// --------------- button -------------------------
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  color:inherit;
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #cececec7;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #cececec7;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 0.9rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #6d6d6d #6d6d6d #ffffff00;
  font-size: 0.9rem;
  background-color: var(--appButtonColorHover) !important;
  color: var(--appButtonText) !important;
  border-color: #9a9a9a !important;
  @include themify($themes) {
    background-color: themed('colorButtonHover') !important;
    color: themed('colorButtonText') !important;
    border-color: #9a9a9a !important;
    }
  }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
  font-size: 0.9rem;
}

.nav-tabs .nav-item.show .nav-link {
  @include themify($themes) {
    color: #495057;
    background-color: #fff;
    border-color: #9a9a9a #9a9a9a themed("colorNavLinkColor") #9a9a9a;
    font-size: 0.9rem;
  }
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

// --------------- tab line -------------------------

// navigation tab shadows
.nav-tabs {
  border-bottom: 1px solid #c7c7c7;
}
// .nav-tabs .nav-link {
//   border: 1px solid #babbbb8a;
//   border-top-left-radius: 0.25rem;
//   border-top-right-radius: 0.25rem;
// }

// --------------- button -------------------------

button,
input,
optgroup,
select,
textarea {
  font-family: "Poppins", "Roboto", sans-serif;
}

// card
.contact-us-wrapper{
  display: flex;
  column-gap: 100px;
  padding: 50px;
  justify-content: center;
  background-image: linear-gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, .5)), linear-gradient(to right, #e1f3dd, #d9f8ff);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact-us-header{
  max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-us-form{
  padding: 40px;
    width: 40%;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-background {
  @include themify($themes){
    background-image: themed('colorBackgroundImage');
  }
}

@media (max-width: 1430px) {
  .contact-us-wrapper{
    flex-direction: column;
    align-content:center;
    align-items:center;
    row-gap:20px;
    text-align:center;
  }

  .contact-us-header{
    max-width:600px;
  }

  .contact-us-form{ 
    width:60%;
  }
}

@media (min-width:600px) and (max-width: 1028px) {
  .contact-us-wrapper {
    flex-direction: column;
    align-content: center;
    align-items: center;
    row-gap: 20px;
    text-align: center;
  }

  .contact-us-header {
    max-width: 600px;
  }

  .contact-us-form {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .contact-us-wrapper {
    flex-direction: column;
    align-content: center;
    align-items: center;
    row-gap: 20px;
    text-align: center;
    padding:30px;
  }

  .contact-us-header {
    max-width: 600px;
  }

  .contact-us-form {
    width: 100%;
    padding:15px;
  }
}

.device-table-wrapper{
  position:relative !important;
  top:-70px !important;
}

.justify-content-center{
  justify-content: center;
}

// freeze header for all the table display
thead{
      position: sticky !important;
        top: 0px;
        margin: 0 0 0 0;
}

.map-marker{
  font-size:14px !important
}

.gm-style .gm-style-iw {
  font-size: 14px !important
}

// for schema chart under asset structure
.schema-chart{
  display:flex;
  overflow: auto;
}

.select-wrapper{
  width: 100px ;
  height: 70px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  overflow: visible !important;
  position: relative !important;
}

.custom-file-label{
  box-shadow: none !important;
  outline:none !important;
}

.custom-file-input:focus~.custom-file-label{
  border-color: var(--appButtonColorHover, #678aff) !important;
}

.mb-15{
  margin-bottom: 15px;
}

.align-items-center{
  align-items: center;
}

.status-line {
  width: 5px;
  background-color: #555;
  max-height: 50px;
  border-radius: 4px;
  margin-right: 10px;
}

.sensor-wrapper {
  display: flex;
  margin-bottom:8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
}

.sensor-label {
  display: flex;
  align-items: center;
}

.sensor-detail {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.sensor-container {
  display: flex;
  justify-content: center;
  flex-direction: column
}

.margin-right-5{
  margin-right:5px
}

.sensor-label{
  font-size:14px;
  color:#6d6d6d
}