.nav-link-effort {
    color: #005b9f !important;
}

.nav-link-effort:hover, .nav-link-effort:focus {
    color: #00335a !important;
    text-decoration: none;
}

.nav-link-effort-pointer {
    cursor: pointer;
}