.max-content-nav {
  /* width: max-content; */
  width: auto;
}

.clean-edge {
  padding: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 7px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.label-for-header {
  padding-top: 20px;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.integration-button {
  padding: 7px 25px;
}