$pulse-speed: 1s;
$pulse-speed-h: 0.5s;
$pulse-speed-m: 1s;
$pulse-speed-l: 1.5s;

$red_color: 'red';
$yellow_color: 'yellow';
$orange_color: 'orange';
$green_color: 'green';

:export { SCSS_GREEN_COLOR: $green_color; }

.pulse{
    animation: pulse $pulse-speed infinite;
    // animation-name: pulse;
    // animation-duration: $pulse-speed;
}
.pulse_high{
    animation: pulse $pulse-speed-h infinite;
}
.pulse_mid{
    animation: pulse $pulse-speed-m infinite;
}
.pulse_low{
    animation: pulse $pulse-speed-l infinite;
}

@keyframes pulse {
   0% {opacity: 1}
//   50% {opacity: 0.5}
//   99% {opacity: 0}
 100% {opacity: 0.2}
}

@keyframes fontBlink {
    from {background-color: white; color: black; font-weight: bold;}
    to {background-color: red; color: white; font-weight: bold;}
}

@keyframes valueBlink {
    from {
        // font-size: 0.8em; 
        font-weight: normal; 
        color: white
    }
    to {
        // font-size: 1.2em; 
        font-weight: bold; 
        color: red
    }
}

@keyframes alertBlink {
    0% {opacity: 1; color: white; font-weight: normal;}
    50% {opacity: 0.3; color: red; font-weight: bold;}
    100% {opacity: 1; color: white; font-weight: normal;}
}

.alertColor {
    color: #ff7f7f
}

span.subsup {
    position: relative;
    }
    
span.subsup sub {
    position: absolute;
    left: 5.5em;
    // bottom: -1.4em;
    bottom: 0em;
}
span.subsup sub.shift {
    // position: absolute;
    left: 8em;
    // bottom: 0em;
}
span.subsup sup {
    // position: absolute;
    left: 0.5em;
}

.css-tlfecz-indicatorContainer{
    cursor:pointer;
}
  
    