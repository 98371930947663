@import "../../../scss/settings/variable";

.wallet-active {
    @include themify($themes) {
        background-color: themed("colorButton");
    }
}
.wallet-style {
    border-radius: 0.25rem;
    padding: 20px;
    margin: 10px;
    box-shadow: 0px 3px 12px 4px rgba(0, 0, 0, 0.16);
}
.display-wallet {
    $rccs-size: 290px;
    $rccs-name-font-size: 17px;
    $rccs-number-font-size: 17px;
    $rccs-valid-font-size: 10px;
    $rccs-expiry-font-size: 16px;
    $rccs-cvc-font-size: 14px;
    @import "react-credit-cards/lib/styles.scss";
}
.wallet-list {
    $rccs-size: 50px;
    $rccs-name-font-size: 0px;
    $rccs-number-font-size: 3.6px;
    $rccs-valid-font-size: 0px;
    $rccs-expiry-font-size: 0px;
    $rccs-cvc-font-size: 0px;
    @import "react-credit-cards/lib/styles.scss";
}
