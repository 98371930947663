@import "../settings/variable.scss";

div {
  .MuiChip-root {
    background-color: palegoldenrod;

    &:focus {
      background-color: Green;
    }

    &:hover {
      background-color: purple;
    }

    &:active {
      background-color: Green;
    }
  }
}

a {
  color: var(--appTableHeader)
    /*!important*/
  ;
}

.mdi-icon {
  fill: var(--appFontColor, #e2e0e0);
  margin-right: 5px;
  //margin-left: 8px;
  //margin-bottom: 10px;

  &.dashboard-checked {
    fill: var(--appButtonColor, #e2e0e0);
  }

  &.unchecked:hover {
    fill: var(--appButtonColor, #e2e0e0);
  }
}


.tree-item:hover {
  background-color: var(--appButtonColor) !important;
}

.dashboard-select {
  &:focus {
    border-color: var(--appButtonColor) !important;
  }
}

.dashboard-select-option {

  // &:hover{
  //   background: transparent !important;
  // }
  &:checked {
    background: var(--appButtonColor) !important;
  }
}





.chip-active {
  @include themify($themes) {
    background-color: themed("sidebarBackground") !important;
  }

  span {
    @include themify($themes) {
      color: themed("sidebarFontColor") !important;
    }
  }

  svg {
    @include themify($themes) {
      color: themed("sidebarFontColor") !important;
    }
  }


}

.chip-inactive {
  @include themify($themes) {
    background-color: themed("colorButton") !important;
    color: themed("colorButtonText") !important;
  }

  svg {
    @include themify($themes) {
      color: themed("colorButtonText") !important;
    }
  }
}

.mode-btn-group {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.mode-btn {
  padding: 5px 10px;
  border-bottom: 0.5px solid $color-gray-inactive;
  margin-bottom: 5px;
  color: $color-gray;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
    color: $color-gray;
  }

  &:focus,
  &:active,
  &:active:focus,
  &:active,
  &.active {
    border-radius: 2px;
    background-color: #f0f0f0;
    color: $color-gray;
    font-weight: 400;
    border-bottom: 2px solid $color-gray-hover;
  }
}


.btn {
  //border-radius: 8px;
  border-radius: 0.25rem;
  padding: 10px 25px;
  margin-bottom: 22px;
  margin-right: 15px;
  transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  // &:last-child {
  //   margin-right: 0;
  // }

  &:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
  }

  p {
    display: flex;
    transition: all 0.3s;
    font-weight: 500;
    color: #444444;
  }

  svg {
    //w&h originally 15px
    height: 20px;
    width: 20px;
    //margin: 2px 5px 0 0;
    margin: 0 0 0 0;
    transition: all 0.3s;
    fill: #444444;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none !important;

    &:before {
      height: 500%;
      width: 225%;
    }
  }

  &:focus,
  &:active,
  &:active:focus {
    &:before {
      transition: all 0s;
    }
  }

  &.square {
    border-radius: 0;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.icon {
    padding: 10px 15px;

    &:hover {
      p {
        color: #646777;
      }

      svg {
        fill: #646777;
      }
    }

    &.icon--right {
      svg {
        margin: 2px 0 0 5px;
      }
    }
  }

  &.btn-sm {
    padding: 5px 25px;
    font-size: 14px;
  }

  &.btn-lg {
    padding: 12px 25px;
    font-size: 14px;
  }

  &.btn-secondary {

    // background-color: #e7e2e2;
    // border-color: #e7e2e2;
    @include themify($themes) {
      background-color: themed("colorButton");
    }

    background-color: var(--appButtonColor); // btn fill color
    border-color: var(--appButtonColor); // btn fill border color
    color: #2b2b2b;

    &:before {
      // background-color: darken(#d2fff5, 17%); // btn hover fill color
      // filter: brightness(120%);
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      filter: brightness(120%);
      // border-color: darken(#2eca7c, 10%); //btn hover border color
      // color: #646777;
      color: #2b2b2b; // btn hover text color
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: #dddddd;
      border-color: #dddddd;
    }
  }

  &.btn-outline-secondary {
    border-color: #e7e2e2;

    &,
    p {
      @include themify($themes) {
        color: themed("colorText");
      }
    }

    svg {
      @include themify($themes) {
        fill: themed("colorText");
      }
    }

    &:before {
      background-color: #e7e2e2;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background: transparent;
      border-color: #e7e2e2;
      color: #444444;

      p {
        color: #444444;
      }

      svg {
        fill: #444444;
      }
    }
  }

  .page-link {
    @include themify($themes) {
      background-color: themed("colorButton");
      border-color: themed("colorButton");
      color: themed("colorButtonText");
    }
  }

  &.btn-success {
    @include themify($themes) {
      background-color: themed("colorButton");
      border-color: themed("colorButton");
      color: themed("colorButtonText");
    }

    &:before {
      @include themify($themes) {
        background-color: themed("colorButtonHover");
      }
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      @include themify($themes) {
        border-color: themed("colorButtonHover");
      }
    }
  }

  &.btn-secondary {
    @include themify($themes) {
      background-color: themed("colorButton");
      border-color: themed("colorButton");
      color: themed("colorButtonText");
    }

    &:before {
      @include themify($themes) {
        background-color: themed("colorButtonHover");
      }
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      @include themify($themes) {
        border-color: themed("colorButtonHover");
      }
    }
  }

  &.btn-custom {
    @include themify($themes) {
      background-color: themed("colorButton");
      border-color: themed("colorButton");
      color: themed("colorButtonText");
    }

    &:before {
      @include themify($themes) {
        background-color: themed("colorButtonHover");
      }
    }

    background-color: var(--colorButton, #c5bfbf);
    border-color: var(--colorButton, #c5bfbf);

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      @include themify($themes) {
        border-color: themed("colorButtonHover");
      }
    }
  }

  &.btn-outline-custom {
    color: var(--appButtonText, $color-blue);
    border-color: var(--appButtonColor, $color-blue);

    :hover:before {
      color: black;
    }

    p {
      color: var(--appButtonText, $color-blue);
    }

    svg {
      fill: var(--appButtonColor, $color-blue);
    }

    &:hover {
      color: var(--appButtonText, white);
    }

    &:before {
      background-color: var(--appButtonColor, $color-blue);
    }
  }

  &.btn-info {
    background-color: #888888;
    border-color: #888888;
    color: var(--appButtonText, $color-dark);

    &:before {
      background-color: $color-gray-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-gray-hover;
    }
  }

  &.btn-primary {
    background-color: $color-blue;
    border-color: $color-blue;

    &:before {
      background-color: $color-blue-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-blue-hover;
    }
  }

  &.btn-filter-inactive {
    border-radius: 1rem;
    background-color: $color-gray-inactive;
    border-color: $color-gray-inactive;

    &:before {
      background-color: $color-gray-inactive-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-gray-inactive-hover;
    }
  }

  &.btn-filter-active {
    border-radius: 1rem;
    background-color: $color-gray-active;
    border-color: $color-gray-active;

    &:before {
      background-color: $color-gray-active-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-gray-active-hover;
    }
  }

  &.btn-outline-primary {
    color: $color-blue;
    border-color: $color-blue;

    p {
      color: $color-blue;
    }

    svg {
      fill: $color-blue;
    }

    &:before {
      background-color: $color-blue;
    }
  }

  &.btn-success {
    background-color: $color-accent;
    border-color: $color-accent;

    &:before {
      background-color: $color-accent-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-accent-hover;
    }
  }

  &.btn-outline-success {
    color: $color-accent;
    border-color: $color-accent;

    p {
      color: $color-accent;
    }

    svg {
      fill: $color-accent;
    }

    &:before {
      background-color: $color-accent;
    }
  }

  &.btn-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: #ffffff;

    &:before {
      background-color: $color-yellow-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-yellow-hover;
    }
  }

  &.btn-outline-warning {
    color: $color-yellow;
    border-color: $color-yellow;

    p {
      color: $color-yellow;
    }

    svg {
      fill: $color-yellow;
    }

    &:before {
      background-color: $color-yellow;
    }
  }

  &.btn-danger {
    background-color: $color-red;
    border-color: $color-red;

    &:before {
      background-color: $color-red-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-red-hover;
    }
  }

  &.btn-outline-danger {
    color: $color-red;
    border-color: $color-red;

    p {
      color: $color-red;
    }

    svg {
      fill: $color-red;
    }

    &:before {
      background-color: $color-red;
    }
  }

  &,
  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success,
  &.btn-outline-secondary,
  &.btn-secondary,
  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success,
  &.icon,
  &.icon.btn-secondary {
    &.disabled {
      background-color: #f2f4f7;
      border-color: #f2f4f7;
      color: #dddddd;
      pointer-events: none;

      p {
        color: #dddddd;
      }

      svg {
        fill: #dddddd;
      }
    }
  }

  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success {
    p {
      color: #ffffff;
    }

    svg {
      fill: #ffffff;
    }
  }

  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success {

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: #ffffff;
      background: transparent;
    }
  }

  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success,
  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success {

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      p {
        color: #ffffff;
      }

      svg {
        fill: #ffffff;
      }
    }
  }

  &.expand {
    svg {
      width: 0;
      transition: all 0.3s;
    }

    &.expand--load {
      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      svg {
        width: 14px;
        animation: rotating 2s linear infinite;
      }
    }
  }
}

.btn-dashboard-edit {

  background-color: var(--appButtonColor);

  @include themify($themes) {
    background-color: themed("colorButton");
    border-color: themed("colorButton");
    color: themed("colorButtonText");
  }

  &:before {
    @include themify($themes) {
      background-color: themed("colorButtonHover");
    }
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    @include themify($themes) {
      border-color: themed("colorButtonHover");
    }
  }
}

.mediaBtn {
  background-color: var(--appButtonColor) !important;
  border-color: var(--colorButton) !important;
  border-radius: 5%;
  margin-top: 15px;
  margin-right: 15px;
}


.btn-toolbar {
  margin-top: 15px;
  margin-bottom: 10px;

  &>* {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: -10px;
  }

  &.btn-toolbar--center {
    &>* {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-group {
  border-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: -10px;
  height: 65px;

  .btn {
    margin-right: 10px;
    padding: 10px 15px;
    font-weight: 500;
  }

  //export to csv button
  .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0.25rem;
  }

  .btn:not(:first-child) {
    border-radius: 0.25rem;
  }

  .btn-add {
    bottom: 10px;
    margin-right: -15px;
    //border-radius: 10px;
  }

  &.btn-group--justified {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &.btn-group--icons {
    .btn {
      padding: 7px 8px;
      line-height: 14px;
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
  }
}

button:focus,
button:active {
  outline: none;
}

.open>.dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:focus,
.btn-default.focus,
.btn-default:focus {
  @include themify($themes) {
    background-color: themed("colorBackground");
  }
}

.actions {
  width: 1px;
  white-space: nowrap;

  a {
    margin-right: 3px;
  }

  a svg {
    color: #b2b2b2;
    transition-duration: 0.2s ease;
  }

  a:hover svg {
    color: #777;
  }
}

.iconHover svg {
  color: #aaa;
  transition-duration: 0.5s ease;
}

.iconHover a:hover svg {
  color: #444;
  transition-duration: 0.2s ease;
}

.selectHov:hover div div {
  border-color: lightgray;
}

.inputHover {
  padding-left: 15px;
  padding-bottom: 8px;
  padding-top: 10px;
  margin-top: 0px;
  border-radius: 0.25rem;
  transition: 0.5s ease;
}

// .iconHover:hover .inputHover{
//   background: #eee;
// }
.inputHover:hover {
  background: #eee;
}

.attributeEdit {
  border: none;
  padding: 0px;
  padding-left: 5px;
}

.attributeEdit:focus {
  box-shadow: none;
  background: #eee;
}

.card-body::-webkit-scrollbar {
  width: 10px;
}

.card-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.card-body::-webkit-scrollbar-thumb {
  background: #888;
}

.card-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.csv-download-btn a {
  color: var(--appButtonText, #000000) !important;
}

.btn-withhover {
  color: black !important;

  &:hover {
    color: white !important;
  }
}

.CalendarDay__selected_span {
  background: var(--sidebarSelected, #66e2da) !important;
  border: 1px double var(--sidebarSelected) !important;
  color: var(--sidebarSelectedFontColor, white) !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--appNavLinkColor, #00a699) !important;
  border: 1px double var(--appNavLinkColor) !important;
  color: '#fff'
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  &:before {
    border-right: 33px solid var(--sidebarSelected) !important
  }
}

.accordion-selected {
  background-color: #dfefff !important;
  font-weight: 500;
}

.in-table {
  max-height: 20px;
}

.select-all {
  padding: 0px 10px;
  background-color: #888 !important;
  border: none;
}

.dropdown {
  .status-on {
    border: 1px solid #86fa8b !important;
  }

  .status-off {
    border: 1px solid #e9b9b9 !important;
  }
}

.dropdown-toggle-button {
  background-color: white !important;
  padding: 2px 15px !important;
  min-width: 50px;
  margin: 0 !important;
  font-size: 12px !important
}

.dropdown-toggle-button-blank {
  background-color: #a2a2a2 !important;
  border: none;
  padding: 0 !important;
}

.dropdown-toggle-button-blank:hover {
  background-color: #a2a2a2 !important;
  padding: 0 !important;
  border-color: #a2a2a2 !important;
}

.dropdown-item {
  padding: 2px 15px !important;
  font-size: 12px !important;
}