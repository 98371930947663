@import "../settings/variable.scss";

.MuiStepLabel-label {
    &.MuiStepLabel-active {
        color: var(--appTextHeader, black) !important;
    }
}

.device-table-row {
    font-size: 0.9em !important;
}

.deviceTable thead tr th {
    font-size: 0.8em !important;
}

.device-permission-table thead {
    z-index: 1;
}

.header-span {
    display: flex;
}

.device-permission-table {
    font-size: 13px !important;
}

.device-permission-table thead tr th {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1em !important;
    background-color: #a2a2a2;
}

.device-permission-table tbody tr td {
    text-align: center;
    // font-size: 13px !important;
}

.device-permission-table tbody tr:nth-child(even) {
    background-color: #e5e5e6 !important;
}


//for action edit page
.row {
    margin-right: 0px;
    // justify-content: center;
    display: flex;
    //margin-left: 0px;
}

.btn-row {
    margin-top: 30px;
}

.MuiStepper-root {
    margin-left: 15px;
    //     margin-right: 15px;
}

.MuiStepIcon-root {

    &.MuiStepIcon-completed,
    &.MuiStepIcon-active {
        color: var(--appButtonColor, #3f51b5) !important;
    }

    .MuiStepIcon-text {
        fill: var(--appButtonText, #fff) !important;
    }
}

.header_white {
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    font-family: "Poppins", "Roboto", Arial, sans-serif;

    @include themify($themes) {
        background-color: themed("colorTableHeader");
    }
}

// th {
//     background-color: black;
// }

// .react-bootstrap-table thead {
//     // display: table;
// }

.react-bootstrap-table {
    display: block;
    max-height: 900px; // 500px;
    /*overflow-y: scroll;*/
    width: 100%;
    overflow: auto;
    color: white;

}

.dropdown-menu {
    min-width: 100px;
    padding: 0 !important;

    li {
        width: 100px;
        padding: 0;
        margin: 0;
    }

    @include themify($themes) {
        background-color: themed("sidebarBackground") !important;
    }

    .dropdown-item:hover {
        @include themify($themes) {
            background-color: themed("colorHover3") !important;
        }
    }

    a {
        @include themify($themes) {
            color: themed("sidebarFontColor") !important;
        }

        display: block;
        width: 100%;
        height: 100%;
        //background-size: cover;
        //text-indent:0.6cm;
        text-align: center;
    }
}

.spinner {
    @include themify($themes) {
        // color: themed("colorButtonColor") !important;
        color: var(--appButtonColorHover, #678aff) !important;
    }
}

.page-item.active {
    .page-link {
        @include themify($themes) {
            color: themed("sidebarFontColor") !important;
            background-color: themed("sidebarBackground");
            border-color: themed("sidebarBackground");
        }

        outline: none;
        box-shadow: none;
    }
}

.page-item {
    .page-link {
        @include themify($themes) {
            background-color: themed("colorButton");
            border-color: themed("colorButton");
            color: themed("colorButtonText") !important;
        }

        outline: none;
        box-shadow: none;
    }
}

.table th,
.table td {
    vertical-align: middle;
}

.table thead {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #c0d9f1;

    @include themify($themes) {
        color: themed("colorTableHeaderFontColor");
    }

    font-style: thin;

    .mdi-icon {
        // fill: var(--appButtonColor, white) !important;
    }
}


.table tbody tr {
    @include themify($themes) {
        color: themed("colorTableRowFontColor");
        background-color: themed("colorTableRowColor");
        font-size: 15px;
    }
}

.react-bs-table table td {
    text-overflow: unset !important;
    white-space: normal !important;
    word-wrap: break-word;

    a {
        color: var(--appHyperLinkColor, #007ad9);
    }
}

.face-reg-table {
    table-layout: auto !important;
}

.ai-records-header {
    font-size: 0.79vw;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    // text-justify: distribute;
}

.ai-records {
    font-size: 0.75vw;
    overflow: visible;
    text-align: left;
    white-space: nowrap;
}

.ai-records img {
    width: 90px;
    border-radius: 5%;
}

.dragBar {
    max-width: 600px;
}

.deviceContainer {
    .react-bootstrap-table {
        height: 570px;
    }
}