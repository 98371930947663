$dynamicTheme: (
  details: (colorBackground: rgb(255, 255, 255),
    //APP - Card background
    colorBackgroundBody: #eaecf1,
    //APP - main background
    colorText: #50525f,
    //APP - Header text color
    colorTextAdditional: #50525f,
    // APP - main background image
    colorBackgroundImage: linear-gradient(to right, #6891e2 0%, #283b4a 100%),
    //Unknown
    logoImg: url(../shared/img/logo/waiv_logo.png),
    //HEADER - Logo
    colorHover: #252f45,
    //SIDEBAR - Open sidebar not selected
    colorHover2: #2e323f,
    //SIDEBAR - select
    colorHover3: #5576a9,
    //SIDEBAR - Hover
    colorHoverTopbarMenuLeft_New: #577ab3,
    //HEADER - sandwich button
    sidebarBackground: linear-gradient(to top right, #6891e2 0%, #283b4a 100%),
    //SIDEBAR - background
    colorHoverTopbarMenuRight_New: #dee5fd,
    //HEADER - button over username
    colorHoverTopRightdropdownMenu_New: #dee5fd,
    //HEADER - dropdown hover over
    colorAvatarNameText_New: #353535,
    //HEADER - Text color
    colorBackgroundTopbarderopdownmenu_New: #ffffff,
    //HEADER - dropdown background
    colorBorder: #eff1f5,
    //Unknown
    colorIcon: #dddddd,
    //Unknown
    colorIcon_New: #242424,
    //HEADER - dropdown icon color
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    //Unknown
    colorBubble: rgba(242, 244, 247, 0.65),
    //Unknown
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    //Unknown
    colorScrollbar: #b4bfd0,
    //Unknown
    colorFitness: #646777,
    //Unknown
  ),
);

/*
    colorBackground: white,
    //colorBackgroundBody: #e5edff52, //#eaecf1,
    colorBackgroundBody: #eaecf1,
    colorText: #50525f,
    colorTextAdditional: #50525f,
    // logoImg: url(../shared/img/logo/sigfox_logo_m.png),
    // logoImg: url(../shared/img/logo/monkey_king_icon_logo.jpg),
    // logoImg: url(../shared/img/logo/alpsalpinegrouplogo.jpg),
    logoImg: url(../shared/img/logo/waiv_logo.png),
    //logoImg: url(../shared/img/logo/waiv_logo_Helix.png), //Helix
    colorHover: #252f45, //#1654b075,
    colorHover2: #2e323f, //selected index item - non-hover box bkg color
    colorHover3: #5576a9, //#456492, //#4c79ff, //selected index item - hover box bkg color

    colorHoverTopbarMenuLeft_New: #577ab3, //#0e2a53, //#003967, //Added
    sidebarBackground: linear-gradient(to top right, #6891e2 0%, #283b4a 100%), //linear-gradient(to top right, #6891e2 0%, #1b2c39 100%), //linear-gradient(to top right, #7095de 0%, #254054 100%), //linear-gradient(to top right, #5a88c4 0%, #223746 100%), //linear-gradient(to top right, #437cae 0%, #12212c 100%), //linear-gradient(to top right, #3bb4ce 0%, #114165 100%), //linear-gradient(to top right, #102f3e 0%, #6592b3 100%), //linear-gradient(to top right, #102f3e 0%, #6584b3 100%), // #2b2b2b, //Added

    colorHoverTopbarMenuRight_New: #dee5fd, //Added
    colorHoverTopRightdropdownMenu_New: #dee5fd, //Added
    
    colorAvatarNameText_New: #353535, //Added
    colorBackgroundTopbarderopdownmenu_New: #ffffff,


    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    colorIcon_New: #242424, //Added

    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
*/


$themes: (
  custom: (colorNavLinkColor: var(--appNavLinkColor),
    //APP - Navlink color
    colorHyperLinkColor: var(--appHyperLinkColor),
    //APP - HyperLink Color
    colorButton: var(--appButtonColor),
    //APP - button color
    colorButtonHover: var(--appButtonColorHover),
    //APP - button color hover
    colorButtonText: var(--appButtonText),
    //APP - button color text
    colorTableHeaderFontColor: var(--appTableHeaderFontColor),
    //App - table header Font color
    colorTableHeader: var(--appTableHeader),
    //APP - table header
    colorTableRowFontColor: var(--appTableFontColor),
    //APP - table Row Font Color
    colorTableRowColor: var(--appTableRow1),
    //APP - table Row Color
    colorHeaderBackground: var(--headerBackground),
    //HEADER - background
    colorBackground: var(--appCardBackground),
    //APP - Card background
    colorBackgroundBody: var(--appBackground),
    //APP - main background
    colorAppColor: var(--appFontColor),
    //APP - main font color
    colorText: var(--appTextHeader),
    //APP - Header text color
    colorTextAdditional: #50525f,
    // APP - main background image
    colorBackgroundImage: var(--appBackgroundImage),
    //Unknown
    colorHover: var(--openSidebarColor),
    //SIDEBAR - Open sidebar not selected
    colorOpenSidebarFont : var(--openSidebarFontColor),
    //SIDEBAR -Open sidebar Font color
    colorHover2: var(--sidebarSelected),
    //SIDEBAR - select
    colorSidebarFontColor: var(--sidebarSelectedFontColor),
    // SIDEBAR - selected font color
    colorHover3: var(--sidebarHover),
    //SIDEBAR - Hover
    colorHoverTopbarMenuLeft_New: var(--headerButtonHoverColor),
    //HEADER - sandwich button
    sidebarBackground: var(--sidebarColor),
    //SIDEBAR - background
    sidebarFontColor: var(--sidebarFontColor),
    //SIDEBAR - Font Color
    colorHoverTopbarMenuRight_New: var(--headerButtonHoverColor),
    //HEADER - button over username
    colorHoverTopRightdropdownMenu_New: var(--headerButtonHoverColor),
    //HEADER - dropdown hover over
    colorAvatarNameText_New: var(--headerFontColor),
    //HEADER - Text color
    colorBackgroundTopbarderopdownmenu_New: var(--headerDropdownColor),
    //HEADER - dropdown background
    colorHeaderDropdownFont: var(--headerDropdownFontColor),
    //HEADER - dropdown font color
    colorBorder: #eff1f5,
    //Unknown
    colorIcon: #dddddd,
    //Unknown
    colorIcon_New: #242424,
    //HEADER - dropdown icon color
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    //Unknown
    colorBubble: rgba(242, 244, 247, 0.65),
    //Unknown
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    //Unknown
    colorScrollbar: #b4bfd0,
    //Unknown
    colorFitness: #646777,
    //Unknown
  ),
  default: (colorNavLinkColor: #3463ff,
    //APP - Navlink color
    colorHyperLinkColor: #007ad9,
    //APP - HyperLink Color
    colorButton: #678aff,
    //APP - button color
    colorButtonHover: #3463ff,
    //APP - button color hover
    colorButtonText: white,
    //APP - button color text
    colorTableHeaderFontColor: black,
    //App - table header Font color
    colorTableHeader: #ebeeff,
    //APP - table header
    colorTableRowFontColor: #000000,
    //APP - table Row Font Color
    colorHeaderBackground: #fff,
    //HEADER - background
    colorBackground: white,
    //APP - Card background
    colorBackgroundBody: #eaecf1,
    //APP - main background
    colorText: #50525f,
    //APP - Header text color
    colorTextAdditional: #50525f,
    // APP - main background image
    colorBackgroundImage: linear-gradient(to right, #6891e2 0%, #283b4a 100%),
    //Unknown
    colorHover: #252f45,
    //SIDEBAR - Open sidebar not selected
    colorOpenSidebarFont : white,
    //SIDEBAR -Open sidebar Font color
    colorHover2: #0d2f6d,
    //SIDEBAR - select
    colorSidebarFontColor: white,
    // SIDEBAR - selected font color
    colorHover3: #5576a9,
    //SIDEBAR - Hover
    colorHoverTopbarMenuLeft_New: #577ab3,
    //HEADER - sandwich button
    sidebarBackground: #6891e2,
    //SIDEBAR - background
    sidebarFontColor: #ffffff,
    //SIDEBAR - Font Color
    colorHoverTopbarMenuRight_New: #dee5fd,
    //HEADER - button over username
    colorHoverTopRightdropdownMenu_New: #dee5fd,
    //HEADER - dropdown hover over
    colorAvatarNameText_New: #353535,
    //HEADER - Text color
    colorBackgroundTopbarderopdownmenu_New: #ffffff,
    //HEADER - dropdown background
    colorBorder: #eff1f5,
    //Unknown
    colorIcon: #dddddd,
    //Unknown
    colorIcon_New: #242424,
    //HEADER - dropdown icon color
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    //Unknown
    colorBubble: rgba(242, 244, 247, 0.65),
    //Unknown
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    //Unknown
    colorScrollbar: #b4bfd0,
    //Unknown
    colorFitness: #646777,
    //Unknown
    // buttonBackgroundColor: #678aff

  ),
  light: (colorBackground: white,
    //colorBackgroundBody: #e5edff52, //#eaecf1,
    colorBackgroundBody: #eaecf1,
    colorText: #50525f,
    colorTextAdditional: #50525f,
    colorBackgroundImage: linear-gradient(to right, #6891e2 0%, #283b4a 100%),
    // logoImg: url(../shared/img/logo/sigfox_logo_m.png),
    // logoImg: url(../shared/img/logo/monkey_king_icon_logo.jpg),
    // logoImg: url(../shared/img/logo/alpsalpinegrouplogo.jpg),
    logoImg: url(../shared/img/logo/waiv_logo.png),
    //logoImg: url(../shared/img/logo/waiv_logo_Helix.png), //Helix
    colorHover: #252f45,
    //#1654b075,
    colorHover2: #2e323f,
    //selected index item - non-hover box bkg color
    colorHover3: #5576a9,
    //#456492, //#4c79ff, //selected index item - hover box bkg color
    colorHoverTopbarMenuLeft_New: #577ab3,
    //#0e2a53, //#003967, //Added
    sidebarBackground: linear-gradient(to top right, #6891e2 0%, #283b4a 100%),
    //linear-gradient(to top right, #6891e2 0%, #1b2c39 100%), //linear-gradient(to top right, #7095de 0%, #254054 100%), //linear-gradient(to top right, #5a88c4 0%, #223746 100%), //linear-gradient(to top right, #437cae 0%, #12212c 100%), //linear-gradient(to top right, #3bb4ce 0%, #114165 100%), //linear-gradient(to top right, #102f3e 0%, #6592b3 100%), //linear-gradient(to top right, #102f3e 0%, #6584b3 100%), // #2b2b2b, //Added
    colorHoverTopbarMenuRight_New: #dee5fd,
    //Added
    colorHoverTopRightdropdownMenu_New: #dee5fd,
    //Added
    colorAvatarNameText_New: #353535,
    //Added
    colorBackgroundTopbarderopdownmenu_New: #ffffff,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    colorIcon_New: #242424,
    //Added
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #b4bfd0,
    colorFitness: #646777,
  ),
  dark: (colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    colorBackgroundImage: linear-gradient(to right, #6891e2 0%, #283b4a 100%),
    // logoImg: url(../shared/img/logo/sigfox_logo_m.png),
    // logoImg: url(../shared/img/logo/monkey_king_icon_logo.jpg),
    // logoImg: url(../shared/img/logo/alpsalpinegrouplogo.jpg),
    logoImg: url(../shared/img/logo/waiv_logo.png),
    //logoImg: url(../shared/img/logo/waiv_logo_Helix.png), //Helix
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: (
        ) !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}"
        );
      $theme-map: map-merge($theme-map,
          ($key: $value,
          )) !global;
    }

    @content;
    $theme-map: null !global;
  }
}
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-accent: #678aff;
$color-accent-New: #9eb2ff;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #383838;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

//$color-blue: #70bbfd;
$color-blue: #678aff;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #888888;
$color-gray-hover: darken($color-gray, 10%);

$color-dark: #000000;
$color-dark-hover: darken($color-dark, 10%);


$color-gray-inactive: #dcdcdc;
$color-gray-inactive-hover: darken($color-gray-inactive, 5%);

$color-gray-active: #7b7b7b;
$color-gray-active-hover: darken($color-gray-active, 5%);

$color-link: #184bda;
$color-link-hover: darken($color-link, 10%);