$themeLogin: (
    custom: (
        loginBackgroundColor: var(--loginBackground),
        buttonColor: var(--buttonColor),
        buttonBackgroundColor: var(--buttonBackground),
        colorSubmitBtnHover: var(--buttonBackground),
        inputBorderColor: var(--inputBorderColor),
        backgroundImage:var(--backgroundImage),
        // loginFont:var(--loginFont),
        loginFontColor:var(--loginFontColor)
    ),
    default: (
      loginBackgroundColor: #f2efef,
      buttonColor: white,
      buttonBackgroundColor: #678aff,
      colorSubmitBtnHover: #678aff,
      inputBorderColor: none,
      backgroundImage:none,
      // loginFont:#678aff,
      loginFontColor:#678aff
    )
);


@mixin themifyLogin($themeLogin) {
    @each $theme, $map in $themeLogin {
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themeLogin, $theme), "#{$key}");
          $theme-map: map-merge(
            $theme-map,
            (
              $key: $value,
            )
          ) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
}

@function themedLogin($key){
    @return map-get($theme-map, $key );
}