.center {
    margin: auto;
    width: 50%;
}

.box-container-ai {
    margin-left: 15%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-template-rows: minmax(auto, auto);
    grid-auto-flow: dense;
    grid-gap: 20px;
    justify-content: center;
}

.box-container-ai .box {
    border-radius: 5px;
    border: 3px solid rgb(112, 110, 110);
    padding: 20px;
    display: grid;
    place-items: center;
    text-align: center;
    grid-column: unset;
    grid-row: unset;
    margin-top: 20px;
    word-break: break-word;
}

.box-container-graph {
    margin-top: 50px;
}

.cam-chart {
    padding: 20px;
}

.stat-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: none;

}

/* this will affect the styling of the invoice under client tab, pls do NOT change it */
/* .stat-table th, td {
    text-align: left;
    padding: 16px;
} */

.stat-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.footage-header {
    margin-bottom: 20px !important;
    font-weight: bold;
    font-size: 1.4em;
}

.capacity {
    /* font-weight: bold; */
    font-size: 1.2em;
}

.noData {
    /* font-weight: bold; */
    margin-left: 33%;
    font-size: 1.2em;
    font-style: italic;
    margin-left: 10px;
}

.limit {
    color: #33cc33 !important;
    font-size: 1.2rem;
}

.limit-edit {}

.box-container-ai .box .stat {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
}

.box-container-ai .box .total {
    color: #33cc33;
}

@media screen and (min-width: 650px) {
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 815px) {
    .box-container-ai .box {
        padding: 0px;
    }

    .box-container-ai .box p {
        font-size: 0.7rem;
    }

    .box-container-ai .box .stat {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2;
    }
}

.sensorStatus {
    font-weight: bold;
    font-size: 16px !important;
    margin-top: 10px;
}