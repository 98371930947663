.deviceCheckouCartPage .row{
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
}

.mainCardBody {
    padding-left: 0px;
    padding-right: 0px;
}