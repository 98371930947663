@import "../settings/variable.scss";

.graphColor {
    @include themify($themes) {
        fill: themed("colorButton");
    }
}
.pricing-card__plan {
    white-space: unset !important;

}
.recharts-tooltip-wrapper {
    .custom-tooltip {
        width: 200px;

        margin: 0;

        line-height: 24px;

        border: 1px solid #f5f5f5;

        background-color: hsla(0, 0%, 100%, 0.8);

        padding: 10px;

        .label {
            color: #666;

            font-weight: 700;
        }

        .intro {
            border-top: 1px solid #f5f5f5;

            margin: 0;
        }

        .desc {
            margin: 0;

            color: #999;
        }
    }
}
