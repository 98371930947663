.div-map {
    cursor: pointer;
    height: 28px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    padding: 0 5px;

    &:hover {
        background-color: var(--appButtonColor);
    }
}