.card {
  width: 100%;
  //padding-bottom: 30px;
  height: 100%;
  border: none; // not to remove - if removed will show lines of box from react-grid-layout outline guides
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }

  .accordion-list-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 50%;
    border: 0.5px solid rgba(0, 0, 0, 0.06);

    span {
      min-width: 170px;
    }
  }

  .list-item-flush {
    border: none;
    width: 30%;
    border-bottom: 0.5px solid rgba(42, 42, 42, 0.2);
  }

  .sub-list-title {
    font-size: 14px;
    font-weight: 500;
    color: #2a2a2a;
    margin-bottom: 3%;
  }



  .load-data-button {
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;
  }

  .button-row {
    margin-top: 25px;
  }

}


.card-header:first-child {
  border-radius: 5px 5px 5px 5px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #e8edfd80; //#c9d4ff80; //#edf2ff; //#f1f9ff; - very light tint of blue //#98ffe9;
  box-shadow: 1px 0px 7px 2px rgba(0, 0, 0, 0.10); //<
  ///border: 1px solid rgba(255, 255, 255, 0); //<
  /* border-bottom: 2px solid white; */
  /* min-width: max-content; */
  min-width: 230px;
  border: 1px solid #b4bad63d;
}

.card-body {

  min-height: auto; // do not use 100% table will overflow
  background-color: #ffffff15;
  border-radius: 8px; //5px;
  ///border: 1px soild #fff; // #929292a1; //<
  // box-shadow: 0px 3px 12px 4px rgba(0, 0, 0, 0.16);//< 
  // box-shadow: inset 0 4px 7px 1px #fff, inset 0 -5px 20px #adbacc3b, 0 2px 6px #00154018, 0 10px 20px #0015400a;
  min-width: 230px;
  margin: 15px;

  // padding: 30px;
  // height: 100%;
  // border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.inner-card-body {
  padding: 30px;
  border-radius: 5px;
}


.card__title {
  margin-bottom: 30px;
  text-transform: none; //uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 1 rem; //13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

//--------- card-body replacement divs for pages ---------//

//--------- card-pg---------// 
//--- another separate card-body box
.theme-light .card-pg {
  background-color: white;
}

.card-pg {
  min-height: auto;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.12);
  min-width: 290px;
  margin-bottom: 10px;
}

.card-pg {
  flex: 1 1 auto;
  padding: 1.25rem;
}


//--------- card-pginside (to be placed inside div className 'card-pg') ---------//
//--- seconary card-body box
.theme-light .card-pginside {
  background-color: white;
}

.card-pginside {
  min-height: auto;
  border-radius: 0px 0px 0px 0px;
  //border: 1px solid rgb(255, 255, 255); //border: 1px solid rgba(233, 6, 6, 0.205);
  min-width: 290px;
}

.card-pginside {
  flex: 1 1 auto;
  padding: 1.25rem;
}




//--------- card-w---------// 
//--- card-body widget
.theme-light .card-w {
  background-color: white;
}

.card-w {
  min-height: auto;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.12);
  // min-width: 290px;
  flex: 1 1 auto;
  padding: 30px;
  margin-bottom: 10px;
}

.card-w {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.chip-background {
  height: 110px;
  overflow-y: scroll;

  @include themify($themes) {
    background-color: themed('colorBackground') !important;
  }
}