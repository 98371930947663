.dashboard_stats_card:hover {
    background-color: rgba(49, 145, 255, 0.089);
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
    cursor: pointer;
}

.spinnerHelix {
    color: #D5B56E !important
}

.center {
    margin: auto;
    width: 50%;
}

.box-container {
    margin: auto;
    width: 85%;
    /* display: grid; */
    display: flex;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-template-rows: minmax(auto, auto);
    grid-auto-flow: dense;
    grid-gap: 20px;
}

.box-container .box {
    border-radius: 15px;
    border: 3px solid black;
    padding: 20px;
    padding-right: 100px;
    padding-left: 100px;
    display: grid;
    place-items: center;
    text-align: center;
    grid-column: unset;
    grid-row: unset;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    word-break: break-word;
}

/* .box-container .box:hover {
    overflow: visible;
} */

@media screen and (min-width: 650px) {
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}