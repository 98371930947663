.zoomBtn{
    border:none;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .7em;
    border-radius: 5px;
    background-color: rgba(172, 172, 172, 0.2);
}

.zoomBtn:hover{
    cursor: pointer;
    background-color: rgba(92, 92, 92, 0.5);
}

.zoomBtn:focus{
    background-color: rgba(99, 99, 99, 0.8);
}